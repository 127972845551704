<template>
  <b-row>
    <b-col v-if="isLoading" cols="12">
      <b-skeleton
        class="skeleton"
        width="100%"
        height="38px"
      />
    </b-col>
    <b-col v-else :cols="cols" class="selectors">
      <multiselect
        v-if="!form.type"
        placeholder="Selecionar tipo de participante"
        v-model="form.type"
        class="with-border"
        :options="participantsOptions"
        :showLabels="false"
        :internalSearch="true"
        :searchable="true"
        :isLoading="isLoading"
        :disabled="isLoading"
      >
        <template #caret>
          <div class="chevron">
            <ChevronDown />
          </div>
        </template>
        <template v-slot:noResult="props">
          <li @click="setCustom(props.search, 'type')">
            <div class="multiselect__option custom-item">
              <Plus class="icon" />
              Adicionar "{{ props.search }}"
            </div>
          </li>
        </template>
      </multiselect>

      <div v-else class="custom-input-prefix">
        <div class="profile-prefix" @click="clearSelectors(order)">
          {{ form.type }}:
        </div>
        <multiselect
          v-if="this.form.type === 'Executante'"
          :value="participantSelected"
          @select="selectParticipant"
          track-by="id"
          label="name"
          placeholder="Selecionar participante"
          :options="this.form.type === 'Executante' ? doctorBeneficiaries : beneficiaries"
          :showLabels="false"
          :internalSearch="true"
          :searchable="true"
          :disabled="isLoading"
          :isLoading="isLoading"
        >
          <template #caret>
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>
          <template v-slot:noResult="props">
            <li @click="setCustom(props.search, 'beneficiary')">
              <div class="multiselect__option custom-item">
                <Plus class="icon" />
                Adicionar "{{ props.search }}" como participante
              </div>
            </li>
          </template>
          <template slot="noResult"> Nenhum resultado </template>
        </multiselect>

        <multiselect
          v-if="this.form.type === 'Instrumentador(a)'"
          :value="participantSelected"
          @select="selectParticipant"
          track-by="id"
          label="name"
          placeholder="Selecionar participante"
          :options="this.form.type === 'Instrumentador(a)' ? instrumentadorBeneficiaries : beneficiaries"
          :showLabels="false"
          :internalSearch="true"
          :searchable="true"
          :disabled="isLoading"
          :isLoading="isLoading"
        >
          <template #caret>
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>
          <template v-slot:noResult="props">
            <li @click="setCustom(props.search, 'beneficiary')">
              <div class="multiselect__option custom-item">
                <Plus class="icon" />
                Adicionar "{{ props.search }}" como participante
              </div>
            </li>
          </template>
          <template slot="noResult"> Nenhum resultado </template>
        </multiselect>

        <multiselect
          v-if="this.form.type === 'Anestesista'"
          :value="participantSelected"
          @select="selectParticipant"
          track-by="id"
          label="name"
          placeholder="Selecionar participante"
          :options="this.form.type === 'Anestesista' ? anestesistaBeneficiaries : beneficiaries"
          :showLabels="false"
          :internalSearch="true"
          :searchable="true"
          :disabled="isLoading"
          :isLoading="isLoading"
        >
          <template #caret>
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>
          <template v-slot:noResult="props">
            <li @click="setCustom(props.search, 'beneficiary')">
              <div class="multiselect__option custom-item">
                <Plus class="icon" />
                Adicionar "{{ props.search }}" como participante
              </div>
            </li>
          </template>
          <template slot="noResult"> Nenhum resultado </template>
        </multiselect>

        <multiselect
          v-if="this.form.type === 'Auxiliar'"
          :value="participantSelected"
          @select="selectParticipant"
          track-by="id"
          label="name"
          placeholder="Selecionar participante"
          :options="this.form.type === 'Auxiliar' ? notSelectedBeneficiaries : beneficiaries"
          :showLabels="false"
          :internalSearch="true"
          :searchable="true"
          :disabled="isLoading"
          :isLoading="isLoading"
        >
          <template #caret>
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>
          <template v-slot:noResult="props">
            <li @click="setCustom(props.search, 'beneficiary')">
              <div class="multiselect__option custom-item">
                <Plus class="icon" />
                Adicionar "{{ props.search }}" como participante
              </div>
            </li>
          </template>
          <template slot="noResult"> Nenhum resultado </template>
        </multiselect>
      </div>
    </b-col>
    <b-col v-if="order > 2">
      <Icon tooltip="Remover participante" class="mt-4 justify-content-center">
        <Delete class="icon ml-auto" @click="removeParticipant(order)" />
      </Icon>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'ParticipantSelectModule',
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Delete: () => import('@/assets/icons/delete.svg'),
    Plus: () => import('@/assets/icons/plus.svg'),
    Icon: () => import('@/components/General/Icon')
  },
  props: {
    removeParticipant: Function,
    participant: {
      type: Object,
      default: () => ({})
    },
    order: {
      type: Number,
      required: true
    },
    beneficiaries: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    participants: {
      type: Array,
      default: () => []
    },
  },
  data: () => ({
    participantsOptions: [
      'Executante',
      'Instrumentador(a)',
      'Anestesista',
      'Auxiliar'
    ],
    form: {
      type: '',
      beneficiary_id: null
    },
  }),
  computed: {
    cols() {
      return this.order > 2 ? 11 : 12
    },
    participantSelected() {
      return (
        this.beneficiaries.find(
          beneficiary => beneficiary.id === this.form.beneficiary_id
        ) || {
          name: this.form.beneficiary_id,
          id: null
        }
      )
    },
    doctorBeneficiaries() {
      return this.beneficiaries.filter(beneficiary => {
        return beneficiary.role === 'DOCTOR' || beneficiary.role === 'DOCTOR_MANAGER'
      })
    },
    anestesistaBeneficiaries() {
      return this.beneficiaries.filter(beneficiary => {
        return beneficiary.role === 'DOCTOR_ANESTHETIST'
      })
    },
    instrumentadorBeneficiaries() {
      return this.beneficiaries.filter(beneficiary => {
        return beneficiary.role === 'TECHNICIAN' || beneficiary.role === 'NURSE' || beneficiary.role === 'TECHNOLOGIST'
      })
    },
    notSelectedBeneficiaries() {
      return this.beneficiaries.filter(
        beneficiary =>
          !this.participants.find(
            participant => participant.beneficiary_id === beneficiary.id && participant.type === 'Executante'
          )
      )
    }
  },
  methods: {
    clearSelectors(order) {
      this.$emit('clearSelectors', order)
    },
    setCustom(value, field) {
      this.form[field] = value
    },
    selectParticipant(value) {
      this.form.beneficiary_id = value.id
    }
  },
  watch: {
    participant: {
      handler(newVal) {
        this.form = newVal
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.selectors {
  width: 100%;
  gap: 10px;
  padding: 15px 10px 5px 10px;
}

.multiselect {
  .custom-item {
    color: var(--blue-500);
    display: flex;
    align-items: center;
    font-weight: 600;
    .icon {
      width: 20px;
      height: 20px;
      fill: var(--blue-500);
      margin-right: 12px;
    }
  }
}
.custom-input-prefix {
  width: 100%;
  display: inline-flex;
  align-items: center;
  border-radius: 8px;
  height: 38px;
  border: 1px solid var(--neutral-300);
  padding: 15px 0 15px 16px;
  font-size: 14px;
  white-space: nowrap;
  margin-bottom: 8px;
  .profile-prefix {
    font-weight: 700;
    color: var(--type-active);
    cursor: pointer;
  }
}
.icon {
  cursor: pointer;
  margin-bottom: 8px;
}
</style>
